import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// import 

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDVLzj7Js96eG9unEMm2BWVBieKxQqD5tQ",
  authDomain: "typingwords-337db.firebaseapp.com",
  projectId: "typingwords-337db",
  storageBucket: "typingwords-337db.appspot.com",
  messagingSenderId: "309969144827",
  appId: "1:309969144827:web:faf4fc102deeb261a0995e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)

export {db};