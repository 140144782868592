import React, { useEffect, useRef, useState } from 'react';
import './home.css';
import { doc, setDoc } from 'firebase/firestore';
import { db } from './Firebase'; // Initialize Firebase

const Home = () => {
  const additionalTypingPracticeWords = [
    "about",
    "above",
    "across",
    "after",
    "against",
    "along",
    "among",
    "around",
    "as",
    "at",
    "before",
    "behind",
    "below",
    "beneath",
    "beside",
    "between",
    "beyond",
    "but",
    "by",
    "concerning",
    "considering",
    "despite",
    "down",
    "during",
    "except",
    "for",
    "from",
    "in",
    "inside",
    "into",
    "like",
    "near",
    "of",
    "off",
    "on",
    "onto",
    "opposite",
    "out",
    "outside",
    "over",
    "past",
    "regarding",
    "since",
    "through",
    "throughout",
    "till",
    "to",
    "toward",
    "under",
    "underneath",
    "until",
    "up",
    "upon",
    "with",
    "within",
    "without",
    "and",
    "but",
    "or",
    "nor",
    "for",
    "yet",
    "so",
    "although",
    "because",
    "since",
    "unless",
    "if",
    "when",
    "while",
    "where",
    "as",
    "than",
    "whether",
    "either",
    "neither",
    "both",
    "though",
    "once",
    "provided",
    "wherever",
    "after",
    "before",
    "until",
    "whereas",
    "apple",
    "book",
    "car",
    "dog",
    "elephant",
    "flower",
    "guitar",
    "house",
    "ice",
    "jungle",
    "kangaroo",
    "lamp",
    "mountain",
    "night",
    "ocean",
    "penguin",
    "queen",
    "river",
    "sun",
    "tree",
    "umbrella",
    "violin",
    "window",
    "xylophone",
    "yacht",
    "zebra",
    "actor",
    "ball",
    "cat",
    "doctor",
    "engine",
    "forest",
    "goal",
    "horse",
    "insect",
    "jacket",
    "kite",
    "lion",
    "monkey",
    "nest",
    "orange",
    "pencil",
    "question",
    "road",
    "star",
    "train",
    "unicorn",
    "vase",
    "wolf",
    "yard",
    "zoo",
    "author",
    "bird",
    "city",
    "dentist",
    "engineer",
    "family",
    "garden",
    "hill",
    "internet",
    "jewel",
    "key",
    "leaf",
    "moon",
    "network",
    "office",
    "piano",
    "queen",
    "river",
    "ship",
    "tiger",
    "universe",
    "valley",
    "water",
    "xenon",
    "yogurt",
    "zebra",
    "baby",
    "bottle",
    "camera",
    "desk",
    "ear",
    "friend",
    "game",
    "hat",
    "idea",
    "juice",
    "king",
    "library",
    "music",
    "note",
    "oil",
    "paper",
    "question",
    "robot",
    "song",
    "truck",
    "umbrella",
    "vaccine",
    "whale",
    "xylophone",
    "year",
    "animal",
    "bed",
    "clock",
    "dragon",
    "egg",
    "forest",
    "garden",
    "heart",
    "island",
    "job",
    "kite",
    "lamp",
    "name",
    "octopus",
    "phone",
    "quiz",
    "road",
    "rock",
    "snake",
    "train",
    "umbrella",
    "vacation",
    "whistle",
    "artist",
    "building",
    "candle",
    "dinosaur",
    "egg",
    "forest",
    "gold",
    "hero",
    "ice",
    "acquiesce",
    "belligerent",
    "cacophony",
    "deleterious",
  
    "mellifluous",
    "nadir",
    "obstreperous",
    "penultimate",
    "quixotic",
    "recalcitrant",
    "susurrus",
    "tantamount",
    "undulate",
    "voracious",
    "widdershins",
    "xylophonist",
    "yesteryear",
    "zoology",
    "acquiesce",
    "belligerent",
    "cacophony",
    "deleterious",
    "ephemeral",
    "facetious",
    "gregarious",
    "harangue",
    "ineffable",
    "juxtaposition",
    "kaleidoscope",
    "lugubrious",
    "magnanimous",
    "nonchalant",
    "obfuscate",
   
    "obstreperous",
    "polemical",
    "quizzical",
    "recalcitrant",
    "sycophant",
    "torpid",
    "ubiquitous",
    "vicarious",
    "wheedle",
    "xenophobic",
    "yonder",
    "zealotry",
    "abrogate",
    "belligerent",
    "caustic",
    "deferential",
    "ebullient",
    "facetious",
    "garrulous",
    "hypothetical",
    "impervious",
    "jubilant",
    "klutzy",
    "loquacious",
    "sagacious",
    "taciturn",
    "unwieldy",
    "voracious",
    "whimsical",
    "zephyr",
    "accolade",
    "bastion",
    "cogent",
    "lucrative",
    "mellifluous",
    "neologism",
    "obdurate",
    "paradigm",
    "quagmire",
    "rapscallion",
    "sanguine",
    "blithe",
    "cabal",
    "dissonance",
    "aberration",
    "juice",
    "kite",
    "lemon",
    "number",
    "ocean",
    "penguin",
    "question",
    "ring",
    "sun",
    "tree",
    "unicorn",
    "violin",
    "window",
    "x-ray",
    "yellow",
    "author",
    "bicycle",
    "city",
    "drum",
    "elephant",
    "flower",
    "grape",
    "hat",
    "ink",
    "jacket",
    "kite",
    "lion",
    "mango",
    "night",
    "ocean",
    "parrot",
    "queen",
    "road",
    "star",
    "train",
    "umbrella",
    "violin",
    "water",
    "xenon",
    "yacht",
    "bat",
    "clock",
    "door",
    "egg",
    "fish",
    "globe",
    "hat",
    "ice",
    "jungle",
    "kite",
    "lamp",
    "moon",
    "night",
    "orange",
    "pizza",
    "queen",
    "rose",
    "sand",
    "tree",
    "universe",
    "violet",
    "water",
    "yogurt",
    "apple",
    "banana",
    "carrot",
    "dog",
    "elephant",
    "fish",
    "grape",
    "horse",
    "ice",
    "jelly",
    "kite",
    "lion",
    "monkey",
    "nest",
    "orange",
    "penguin",
    "quiz",
    "rose",
    "sun",
    "train",
    "umbrella",
    "violet",
    "water",
    "yogurt",
    "ant",
    "bird",
    "cat",
    "dog",
    "fish",
    "goat",
    "hat",
    "jacket",
    "lion",
    "monkey",
    "nest",
    "orange",
    "pizza",
    "queen",
    "rose",
    "sun",
    "tree",
    "umbrella",
    "violet",
    "fish",
    "goat",
    "hat",
    "ice",
    "jacket",
    "kite",
    "lion",
    "monkey",
    "nest",
    "air",
    "bicycle",
    "cat",
    "door",
    "egg",
    "flute",
    "grape",
    "honey",
    "ice",
    "jelly",
    "key",
    "lemon",
    "moon",
    "night",
    "orange",
    "pencil",
    "quilt",
    "rose",
    "star",
    "truck",
    "umbrella",
    "vase",
    "wheel",
    "cake",
    "desk",
    "elephant",
    "frog",
    "guitar",
    "hat",
    "igloo",
    "jungle",
    "kite",
    "lamp",
    "mountain",
    "net",
    "octopus",
    "pencil",
    "quiz",
    "rose",
    "star",
    "tree",
    "unicorn",
]

  const [loading, setLoading] = useState(true);
  
  // Function to add words to Firestore
  const saveWordsToFirestore = async () => {
    setLoading(true);
    try {
      const docRef = doc(db, 'wordCollections', 'midum'); // Specify your collection and document ID
      await setDoc(docRef, {
        words: additionalTypingPracticeWords
      });
      console.log('Words saved successfully!');
    } catch (error) {
      console.error('Error saving words: ', error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="homes">
      <button onClick={saveWordsToFirestore}>Add words just click this</button>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <p>Words have been saved to Firestore!</p>
      )}
    </div>
  );
};

export default Home;

