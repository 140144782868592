import React, { useState, useEffect, useRef } from 'react';
import Timer from './Timer';
import TextDisplay from './TextDisplay';
import './styles.css';
import { db } from '../Firebase';
import { doc, getDoc } from 'firebase/firestore';
import img from './logo.png'

const TypingTest = () => {
    const [randomParagraph, setRandomParagraph] = useState('');
    const [wordsArray, setWordsArray] = useState([]);
    const [input, setInput] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [correctCount, setCorrectCount] = useState(0);
    const [timeLimit, setTimeLimit] = useState(60); // 60 seconds
    const [timeRemaining, setTimeRemaining] = useState(timeLimit);
    const [testOver, setTestOver] = useState(false);
    const typingAreaRef = useRef(null);
    const timerRef = useRef(null); // Use a ref to store the timer

    const getArrayFromFirestore = () => {
        const docRef = doc(db, 'wordCollections', 'midum');

        return getDoc(docRef)
            .then((deta) => {
                if (deta.exists()) {
                    const data = deta.data();
                    const wordsArray = data.words;
                    setWordsArray(wordsArray);
                    console.log("Fetched Array:", wordsArray);
                } else {
                    console.log("No such document!");
                }
            })
            .catch((error) => {
                console.error("Error fetching document: ", error);
            });
    };

    const getRandomParagraph = (count) => {
        if (wordsArray.length === 0) return;

        const randomIndices = new Uint32Array(count);
        crypto.getRandomValues(randomIndices);
        const words = Array.from(randomIndices, index => wordsArray[index % wordsArray.length]);

        const paragraph = words.join(' ');
        setRandomParagraph(paragraph);
    };

    useEffect(() => {
        getArrayFromFirestore();
        typingAreaRef.current.focus(); // Focus on the typing area when the component mounts
    }, []);

    useEffect(() => {
        if (wordsArray.length > 0) {
            getRandomParagraph(23); // Generate a random paragraph with 40 words
        }
    }, [wordsArray]);

    useEffect(() => {
        if (timeRemaining === 0) {
            setTestOver(true);
            setIsTyping(false);
            clearInterval(timerRef.current); // Clear timer on test over
        }
    }, [timeRemaining]);

    useEffect(() => {
        if (!testOver) {
            typingAreaRef.current.focus(); // Refocus when the test is restarted
        }
    }, [testOver]);

    const handleKeyDown = (e) => {
        // Restart test if test is over and Enter is pressed
        if (testOver && e.key === 'Enter') {
            restartTest();
            return;
        }

        // Handle space, backspace, and normal key inputs during the test
        if (e.key === ' ') {
            e.preventDefault();
            checkCurrentWord();
        } else if (e.key === 'Backspace') {
            e.preventDefault();
            if (input.length > 0) {
                setInput(input.slice(0, -1));
            }
        } else if (e.key.length === 1) {
            if (!isTyping) {
                setIsTyping(true);
                startTimer(); // Start timer only when typing begins
            }
            setInput((prev) => prev + e.key);
        }
    };

    const startTimer = () => {
        clearInterval(timerRef.current); // Clear any existing timer
        setTimeRemaining(timeLimit); // Reset timer
        timerRef.current = setInterval(() => {
            setTimeRemaining((prev) => {
                if (prev <= 1) {
                    clearInterval(timerRef.current);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);
    };

    const checkCurrentWord = () => {
        const currentInputWord = input.trim();
        const currentWords = randomParagraph.split(' ');
        const correctWord = currentWords[currentWordIndex];

        // Whether correct or not, update the word index and remove the first word
        if (currentInputWord === correctWord) {
            setCorrectCount((prevCount) => prevCount + 1); // Increment count only if correct
        }

        // Always move to the next word by removing the first word
        const newWordsArray = [...currentWords.slice(1)]; // Remove the first word

        // Get a new random word from wordsArray and add it to the end
        const randomIndex = Math.floor(Math.random() * wordsArray.length);
        newWordsArray.push(wordsArray[randomIndex]);

        // Update the paragraph with the new set of words
        setRandomParagraph(newWordsArray.join(' '));

        // Clear the input regardless of correctness
        setInput('');
    };

    const restartTest = () => {
        setInput(''); // Clear input
        setCurrentWordIndex(0); // Reset word index
        setCorrectCount(0); // Reset correct word count
        setIsTyping(false); // Reset typing state
        setTestOver(false); // Reset test over state
        getRandomParagraph(23); // Generate a new random paragraph for the new test
        // Do not start the timer here; it will start when the user types
        typingAreaRef.current.focus(); // Focus on the typing area
    };

    const [darkMode, setDarkMode] = useState(true);
    const toggleTheme = () => {
        setDarkMode(prevMode => !prevMode);
    };

    useEffect(() => {
        document.body.className = darkMode ? 'dark' : 'light';
    }, [darkMode]);

    return (
        <div className="home">
            <img src={img} alt="" height={80}/>
            <div className="np">
                <button onClick={toggleTheme}>
                    {darkMode ? 'Light' : 'Dark'}
                </button>
            </div>
            <div className="TypingTest" onKeyDown={handleKeyDown} tabIndex="0" ref={typingAreaRef}>
                <div className="typing-area">
                    {/* Only show Timer and TextDisplay when the test is not over */}
                    {!testOver && (
                        <>
                            <Timer timeRemaining={timeRemaining} />
                            <TextDisplay sampleText={randomParagraph} input={input} currentWordIndex={currentWordIndex} />
                        </>
                    )}
                    <div>Correct Words: {correctCount}</div>
                </div>
                {testOver && (
                    <div className="results">
                        <h2>Test Over!</h2>
                        <p>WPM : {correctCount}</p>
                        <button onClick={restartTest}>Start New Test</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TypingTest;
