import React, { useState, useEffect, useRef } from 'react';
import Timer from './Timer';
import TextDisplay from './TextDisplay';
import './styles.css';
import { db } from '../Firebase';
import { doc, getDoc } from 'firebase/firestore';

const TypingTest1 = () => {
    const [randomParagraph, setRandomParagraph] = useState('');
    const [wordsArray, setWordsArray] = useState([]);
    const [input, setInput] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [correctCount, setCorrectCount] = useState(0);
    const [timeLimit, setTimeLimit] = useState(15); // 5 seconds for testing
    const [timeRemaining, setTimeRemaining] = useState(timeLimit);
    const [testOver, setTestOver] = useState(false);
    const typingAreaRef = useRef(null);
    const timerRef = useRef(null); // Use a ref to store the timer

    const getArrayFromFirestore = () => {
        const docRef = doc(db, 'wordCollections', 'midum');

        return getDoc(docRef)
            .then((deta) => {
                if (deta.exists()) {
                    const data = deta.data();
                    const wordsArray = data.words;
                    setWordsArray(wordsArray);
                    console.log("Fetched Array:", wordsArray);
                } else {
                    console.log("No such document!");
                }
            })
            .catch((error) => {
                console.error("Error fetching document: ", error);
            });
    };

    const getRandomParagraph = (count) => {
        if (wordsArray.length === 0) return;

        const randomIndices = new Uint32Array(count);
        crypto.getRandomValues(randomIndices);
        const words = Array.from(randomIndices, index => wordsArray[index % wordsArray.length]);

        const paragraph = words.join(' ');
        setRandomParagraph(paragraph);
    };

    useEffect(() => {
        getArrayFromFirestore();
        // Focus on the typing area when the component mounts
        typingAreaRef.current.focus();
    }, []); // Run once on mount

    useEffect(() => {
        if (wordsArray.length > 0) {
            getRandomParagraph(10); // Generate a random paragraph after fetching words
        }
    }, [wordsArray]);

    useEffect(() => {
        if (timeRemaining === 0) {
            setTestOver(true);
            setIsTyping(false);
            clearInterval(timerRef.current); // Clear timer on test over
        }
    }, [timeRemaining]);

    useEffect(() => {
        if (!testOver) {
            typingAreaRef.current.focus(); // Refocus when the test is restarted
        }
    }, [testOver]);

    const handleKeyDown = (e) => {
        if (e.key === ' ') {
            e.preventDefault();
            checkCurrentWord();
        } else if (e.key === 'Backspace') {
            e.preventDefault();
            if (input.length > 0) {
                setInput(input.slice(0, -1));
            }
        } else if (e.key.length === 1) {
            if (!isTyping) {
                setIsTyping(true);
                startTimer(); // Start timer when typing begins
            }
            setInput((prev) => prev + e.key);
        }
    };

    const startTimer = () => {
        clearInterval(timerRef.current); // Clear any existing timer
        setTimeRemaining(timeLimit); // Reset timer
        timerRef.current = setInterval(() => {
            setTimeRemaining((prev) => {
                if (prev <= 1) {
                    clearInterval(timerRef.current);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);
    };

    const checkCurrentWord = () => {
        const currentInputWord = input.trim();
        const correctWord = randomParagraph.split(' ')[currentWordIndex]; // Use the random paragraph

        if (currentInputWord === correctWord) {
            setCorrectCount((prevCount) => prevCount + 1);
        }

        // Move to the next word only if the current input word is correct
        if (currentInputWord === correctWord) {
            setCurrentWordIndex((prevIndex) => {
                const nextIndex = prevIndex + 1;
                // Check if the next index is beyond the last word
                if (nextIndex < randomParagraph.split(' ').length) {
                    return nextIndex; // Move to the next word
                } else {
                    setTestOver(true); // Set test over when all words are typed
                    clearInterval(timerRef.current); // Clear the timer
                    return prevIndex; // Keep the index at the last word
                }
            });
        }
        setInput(''); // Clear the input regardless of correctness
    };

    const restartTest = () => {
        setInput(''); // Clear input
        setCurrentWordIndex(0); // Reset word index
        setCorrectCount(0); // Reset correct word count
        setIsTyping(false); // Reset typing state
        setTestOver(false); // Reset test over state
        getRandomParagraph(10); // Generate a new random paragraph for the new test
        startTimer(); // Start a new timer
        typingAreaRef.current.focus(); // Focus on the typing area
    };

    return (
        <div className="TypingTest" onKeyDown={handleKeyDown} tabIndex="0" ref={typingAreaRef}>
            <div className="typing-area">
                {/* Only show Timer and TextDisplay when the test is not over */}
                {!testOver && (
                    <>
                        <Timer timeRemaining={timeRemaining} />
                        <TextDisplay sampleText={randomParagraph} input={input} currentWordIndex={currentWordIndex} />
                    </>
                )}
                <div>Correct Words: {correctCount}</div>
            </div>
            {testOver && (
                <div className="results">
                    <h2>Test Over!</h2>
                    <p>WPM: {correctCount}</p>
                    <button onClick={restartTest}>Start New Test</button>
                </div>
            )}
        </div>
    );
};

export default TypingTest1;

