import React from 'react';

const TextDisplay = ({ sampleText, input, currentWordIndex }) => {
  const words = sampleText.split(' ');
  const currentWord = words[currentWordIndex] || '';

  return (
    <div className="text-display" aria-live="polite">
      {words.map((word, wordIndex) => {
        const isCurrentWord = wordIndex === currentWordIndex;
        const isCompleted = wordIndex < currentWordIndex || (wordIndex === currentWordIndex && input.trim() === word);
        
        return (
          <span key={wordIndex} className="word">
            {word.split('').map((char, charIndex) => {
              const isCurrentChar = isCurrentWord && charIndex === input.length;

              let charClass = ''; // Default class for all characters
              
              if (isCurrentWord && charIndex < input.length) {
                // Check correctness only for the current word
                if (input[charIndex] === char) {
                  charClass = 'correct'; // Class for correct letters
                } else if (input[charIndex] !== undefined) {
                  charClass = 'incorrect'; // Class for incorrect letters
                }
              }

              return (
                <span
                  key={charIndex}
                  className={charClass}
                  style={{
                    textDecoration: isCurrentChar ? 'underline' : 'none',
                    color: isCompleted ? 'rgba(228, 228, 228, 1)' : 'grey', // Change to white for completed words
                    backgroundColor: charClass === 'incorrect' ? '#fcae1e' : charClass === 'correct' ? 'rgba(0, 255, 0, 0.2)' : 'transparent',
                    transition: 'background-color 0.1s', // Smooth transition for background color
                  }}
                >
                  {char}
                </span>
              );
            })}
            <span style={{ color: isCompleted ? 'white' : 'transparent' }}> </span> {/* Space after each word */}
          </span>
        );
      })}
    </div>
  );
};

export default TextDisplay;
