import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Home';
import TypingTest from './component/TypingTest';
import TypingTest1 from './component/TypingTest1';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<TypingTest />} />
        <Route path="/page2" element={<TypingTest1 />} />
        <Route path="/homeadmin" element={<Home />} />
      </Routes>
      
      </BrowserRouter>
    </div>
  );
}

export default App;
